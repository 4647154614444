.header-nav {
  width: 100%;
  min-width: 390px;
  position: fixed;
  top: 0;
  left: 0;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
  }
  
  li {
    float: left;
  }
  
  .linkk {
    display: block;
    color: white;
    text-align: center;
    padding: 12px 6px;
    text-decoration: none;
  }
  
  .linkk:hover:not(.active) {
    background-color: #111;
  }
  
  .active {
    background-color: #04AA6D;
  }