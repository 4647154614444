/* Common Styles */
.main {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 90px 0;
  background-color: rgb(173, 173, 218);
}

.sub-main,
.sub-main-signup,
.sub-main-subscribe,
.unsub-main {
  display: flex;
  justify-content: center;
  border-radius: 60px;
  box-shadow: 11px 12px 13px 12px rgb(207, 207, 207);
  background-color: white;
}

.container-image {
  background-color: rgb(207, 207, 207);
  border-radius: 50%; /* Adjusted */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 115px;
  width: 115px;
}

.bttn {
  width: 380px;
  height: 50px;
  border-radius: 60px;
  background-color: rgb(12, 174, 69);
  color: white;
  font-size: 25px;
  border: none;
}

/* Login Form */
.sub-main {
  height: 500px;
  width: 35%;
  padding-top: 30px;
}

.imgs {
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.profile {
  height: 107px;
  width: 113px;
  border-radius: 130px;
}

.input {
  width: 300px;
  height: 50px;
  border-radius: 60px;
  box-shadow: inset 0px 0px 25px 0px #888;
  border: none;
  outline: none;
  background-color: #fff;
}

.email {
  height: 40px;
  width: 40px;
  position: absolute;
  padding-top: 5px;
  padding-left: 15px;
}

.name {
  padding-left: 70px;
  font-size: 20px;
  width: 300px;
  height: 50px;
  border-radius: 60px;
  box-shadow: inset 0px 0px 25px 0px #888;
  border: none;
  outline: none;
  background-color: #fff;
}

.second-div {
  padding-top: 10px;
}

.bttn-div {
  padding-top: 18px;
}

.link {
  font-size: 25px;
  font-weight: 400;
}

/* Signup Form */
.sub-main-signup {
  height: 620px; /* Adjusted */
  width: 35%;
  padding-top: 5px;
}

/* Subscribe Page */
.sub-main-subscribe {
  height: 500px;
  width: 35%;
  padding-top: 80px;
}

/* Unsubscribe Page */
.unsub-main {
  height: 200px;
  width: 75%;
  padding-top: 30px;
  border-radius: 20px;
  text-align: center;
  align-items: center;
  background-color: rgb(173, 173, 218);
}

/* Responsive Design */
@media only screen and (max-width: 1154px) {
  .sub-main,
  .sub-main-signup,
  .sub-main-subscribe {
      width: 300px;
  }
}

@media only screen and (max-width: 595px) {
  .name {
      width: 250px;
  }
  .bttn {
      width: 250px;
  }
  .sub-main,
  .sub-main-signup,
  .sub-main-subscribe {
      width: 300px;
  }
}

@media only screen and (max-width: 410px) {
  .unsub-main {
      width: 100px;
  }
  .font {
      font-size: small;
  }
}
