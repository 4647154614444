.home-imgg{
    background-color: rgb(207,207,207);
    border-radius:150px;
    align-items:center;
    display:flex;
    height:180px;
    width:370px;
    padding-left:10px;
    padding-top:10px;
  }
  
  .home-footer{
    background-color:black;
    color:white;
    text-align:center;
  }
  
  .home-color{
    color:white;
  }