.table-div {
  position: relative;
  top: 50px;
  overflow: auto;
}

@media only screen and (max-width:535px) {
    .size{
      width: 150px !important;
      /* margin-left: 0px;
      padding-left: 0px; */
    }  
  }

  @media only screen and (max-width:400px) {
    .size{
      width:130px !important;
      /* margin-left: 0px;
      padding-left: 0px; */
    }  
  }

  .search{
    width:150px;
    height:38px;
    border-radius:60px;
    box-shadow:inset 0px 0px 25px 0px #888;
    border:none;
    outline:none;
    background-color:#fff;
    padding-left: 10px;
    margin-bottom: 5px;
    float: right;
  }

  .searchh{
    width:150px;
    height:38px;
    border-radius:60px;
    box-shadow:inset 0px 0px 25px 0px #888;
    border:none;
    outline:none;
    background-color:#fff;
    padding-left: 10px;
    padding-right:20px;
    margin-bottom: 5px;
    /* float: right; */
  }

  .clr{
    color: red !important;
    
  }

  .clr2{
    color:violet !important;
  }

  .spinnerr{
    display:flex;
    text-align: center;
    justify-content: center;
    position: relative;
    /*left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid #FFFF00;
    padding: 10px; */
  }

  .spinnerrr{
    position: absolute;

  }

  .searchInput{
    margin-top: 80px;
    margin-bottom: 1px;
  }

  .pageDiv{
    justify-content: center;
    margin-top:80px;
  }

  .head{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 60px;
    text-align: center;
  }